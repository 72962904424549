import {
    EuiBasicTableColumn,
    EuiButton,
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiInMemoryTable,
    EuiLink,
    EuiPage,
    EuiPanel,
    EuiSpacer,
    EuiText,
    EuiTitle,
} from "@elastic/eui";
import client from "api/Client";
import ConfirmationDialog from "components/modals/ConfirmationDialog";
import API_URL from "Constants";
import React, { useContext, useEffect, useState } from "react";
import { ShopContext } from "utils/Shop";
import { PriceForTable } from "utils/types";

import { PriceGrid } from "../components/PriceGrid";

export default function PricesPage() {
    const [prices, setPrices] = useState<PriceForTable[]>([]);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [deleteId, setDeleteId] = useState("");

    const { shopId } = useContext(ShopContext);

    useEffect(() => {
        client.get(`${API_URL}/v1/shop-groups?skip=0&limit=1000`).then((result) => {
            const shopGroupIds = result.data
                .filter((group) => group.shop_ids.includes(shopId))
                .map((group) => group.id);
            client.get(`${API_URL}/v1/prices?skip=0&limit=1000&sort=internal_product_id`).then((result) => {
                const prices: PriceForTable[] = [];
                client.get(`/v1/shops-to-prices?limit=1000`).then((stpResult) => {
                    result.data
                        .filter((price) => shopGroupIds.includes(price.shop_group_id))
                        .forEach((price) => {
                            const field =
                                price.cannabis && price.cannabis.length
                                    ? "cannabis"
                                    : price.pre_rolled_joints && price.pre_rolled_joints.length
                                    ? "pre_rolled_joints"
                                    : "edible";

                            const priceForTable: PriceForTable = {
                                id: price.id,
                                internal_product_id: price.internal_product_id,
                                shop_group_id: price.shop_group_id,
                                created_at: price.created_at,
                                modified_at: price.modified_at,
                                prices: {
                                    "0,5g": price["half"],
                                    "1g": price["one"],
                                    "2,5g": price["two_five"],
                                    "5g": price["five"],
                                    joint: price["joint"],
                                    piece: price["piece"],
                                },
                                model: price[field] != null && price[field].length > 0 ? "New" : "Old",
                                active: stpResult.data.find((stp) => stp.price_id === price.id) ? "Ja" : "Nee",
                            };

                            if (price[field] != null && price[field].length > 0) {
                                for (let i = 0; i < price[field].length; i++) {
                                    priceForTable.prices[
                                        price[field][i]["label" as keyof object]
                                            .toString()
                                            .replace(" ", "")
                                            .replace("gram", "g")
                                    ] = price[field][i]["price" as keyof object];
                                }
                            }
                            prices.push(priceForTable);
                        });
                    setPrices(prices);
                });
            });
        });
    }, [shopId]);

    function handleDelete(id: string) {
        //Delete shop-to-price relation first
        client.get(`/v1/shops-to-prices?filter=price_id%3A${id}`).then((result) => {
            if (result.data.length > 0) {
                // Todo: Show toast that they should delete active prices first
                alert("Deze prijs combi / kassa code heeft nog actieve producten op de kaart. Wis die eerst.");
            } else {
                client.delete(`/v1/prices/${id}`).then(() => {
                    window.location.reload();
                });
            }
        });
    }

    const columns: EuiBasicTableColumn<PriceForTable>[] = [
        {
            field: "id",
            name: "ID",
            width: "10%",
            render: (id: string) => <span>{id.substring(0, 8)}</span>,
        },
        {
            field: "internal_product_id",
            name: "Code",
            width: "30%",
            render: (internal_product_id: string) => <EuiText size={"s"}>{internal_product_id}</EuiText>,
        },
        {
            field: "model",
            name: "Prijs Model",
            width: "10%",
            render: (model: string) => <span>{model}</span>,
        },
        {
            field: "prices",
            name: "Prijzen",
            width: "30%",
            render: (prices: Record<string, number>) => (
                <PriceGrid
                    prices={prices}
                    record={undefined}
                    setEditRecord={() => {}}
                    getModalPrices={() => {}}
                    mode={"cannabis"}
                />
            ),
        },
        {
            field: "active",
            name: "Actief",
            width: "10%",
            render: (active: string) => <>{active}</>,
        },
        // {
        //     field: 'created_at',
        //     name: 'Aangemaakt',
        //     width: '15%',
        //     render: (created_at: string) => <span>{created_at}</span>
        // },
        // {
        //     field: 'modified_at',
        //     name: 'Aangepast',
        //     width: '15%',
        //     render: (modified_at: string) => <span>{modified_at}</span>
        // },
        {
            field: "id",
            name: "",
            width: "10%",
            render: (id: string) => (
                <EuiFlexGroup>
                    <EuiFlexItem style={{ marginLeft: 30, marginRight: 20 }}>
                        <EuiLink href={`/prices/edit/${id}`}>
                            <EuiButtonIcon aria-label="edit" iconType="pencil" />
                        </EuiLink>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiButtonIcon
                            aria-label="trash"
                            iconType="trash"
                            onClick={() => {
                                setDeleteId(id);
                                setConfirmDialogOpen(true);
                            }}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            ),
        },
    ];

    return (
        <EuiPage>
            <EuiPanel paddingSize="l">
                {confirmDialogOpen && (
                    <ConfirmationDialog
                        isOpen={confirmDialogOpen}
                        cancel={() => setConfirmDialogOpen(false)}
                        confirm={() => {
                            handleDelete(deleteId);
                            setConfirmDialogOpen(false);
                        }}
                        question={"Weet je zeker dat je deze wilt wissen?"}
                    />
                )}
                <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
                    <EuiTitle>
                        <EuiText>Prijs Combi's en kassa codes</EuiText>
                    </EuiTitle>
                    <EuiLink href={"/prices/new"}>
                        <EuiButton iconType={"plus"}>Prijs</EuiButton>
                    </EuiLink>
                </EuiFlexGroup>
                <EuiSpacer />
                {prices.length > 0 && (
                    <EuiInMemoryTable columns={columns} items={prices} pagination={{ pageSize: 200 }} />
                )}
            </EuiPanel>
        </EuiPage>
    );
}
