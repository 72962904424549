import client from "../api/Client";
import { S3_BUCKET } from "../Constants";

export function stop(e) {
    if (e !== undefined && e !== null) {
        e.preventDefault();
        e.stopPropagation();
    }
}

export function isEmpty(obj) {
    if (obj === undefined || obj === null) {
        return true;
    }
    if (Array.isArray(obj)) {
        return obj.length === 0;
    }
    if (typeof obj === "string") {
        return obj.trim().length === 0;
    }
    if (typeof obj === "object") {
        return Object.keys(obj).length === 0;
    }
    return false;
}

export function endsWithAny(suffixes, string) {
    return suffixes.some(function (suffix) {
        return string.endsWith(suffix);
    });
}

export function resetAppState() {
    const appState = {
        user: { isLoggedIn: false, profile: undefined },
    };
    localStorage["appState"] = JSON.stringify(appState);
    // Remove serversided session also
    client.get(`/logout`).then().catch();
    return appState;
}

export function setTheme(theme) {
    if (theme === "light") {
        localStorage.removeItem("darkMode");
        window.location.reload();
    } else {
        localStorage.setItem("darkMode", "yes");
        window.location.reload();
    }
}

export function removeTableSettingsFromLocalStorage() {
    localStorage.removeItem("table-settings:table.orders-page.pending");
    localStorage.removeItem("table-settings:table.orders-page.complete");
    sessionStorage.removeItem("table-settings:table.orders-page.pending");
    sessionStorage.removeItem("table-settings:table.orders-page.complete");
}

export function capitalizeFirstLetter(word) {
    return word !== undefined ? word.charAt(0).toUpperCase() + word.slice(1) : "";
}

export function removePlural(word) {
    if (word !== undefined && word.endsWith("ies")) {
        return word.replace(/ies$/, "y");
    }
    return word?.replace(/(s|en)$/, "");
}

const replacePointWithComma = (str) => {
    return str.replace(".", ",");
};

export function formatMoney(amount, format = "long", decimalCount = 2, decimal = ",", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";
        let formattedAmount = "";

        switch (format) {
            case "short":
                formattedAmount = `${negativeSign + amount}`;
                break;

            case "short_euro":
                formattedAmount = `€ ${negativeSign + amount}`;
                break;

            case "long":
            case "long_euro":
                let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
                let j = i.length > 3 ? i.length % 3 : 0;

                formattedAmount = `${negativeSign}${j ? i.substr(0, j) + thousands : ""}${i
                    .substr(j)
                    .replace(/(\d{3})(?=\d)/g, "$1" + thousands)}${
                    decimalCount
                        ? decimal +
                          Math.abs(amount - i)
                              .toFixed(decimalCount)
                              .slice(2)
                        : ""
                }`;

                if (format === "long_euro") {
                    formattedAmount = `€ ${formattedAmount}`;
                }
                break;

            default:
                throw new Error("Invalid format type");
        }

        return replacePointWithComma(formattedAmount);
    } catch (e) {
        console.log(e);
    }
}

export function convertToRoundPrice(value) {
    if (typeof value === "string") {
        value = parseFloat(value);
    }

    if (typeof value !== "number" || isNaN(value)) {
        throw new Error("Invalid input: value must be a number or a numeric string.");
    }

    return value.toFixed(2);
}

export function getPriceInfo(price) {
    let result = [];
    if (price.half) {
        result.push(`0.5g: ${formatMoney(price.half)}`);
    }
    if (price.one) {
        result.push(`1g: ${formatMoney(price.one)}`);
    }
    if (price.two_five) {
        result.push(`2.5g: ${formatMoney(price.two_five)}`);
    }
    if (price.five) {
        result.push(`5g: ${formatMoney(price.five)}`);
    }
    if (price.joint) {
        result.push(`joint: ${formatMoney(price.joint)}`);
    }
    if (price.piece) {
        result.push(`${formatMoney(price.piece)}`);
    }
    return result.join(" ");
}

export function getPriceQuantityDescription(priceQuantity) {
    const priceOptions = {
        half: "0,5g",
        one: "\xa0\xa01g",
        two_five: "2,5g",
        five: "\xa0\xa0\xa05g",
        joint: "\xa0Joint",
        piece: "",
    };
    return priceOptions[priceQuantity];
}

export function getCategoryNameFromPrices(prices) {
    if (prices.length > 0) {
        return prices[0]["category_name"];
    }
    return "";
}

export function imageCounter(product) {
    let imageCount = 0;
    for (let key of Object.keys(product)) {
        if (key.startsWith("image_")) {
            imageCount++;
        }
    }
    return imageCount;
}

export function simulateMouseClick(element) {
    element.dispatchEvent(
        new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1,
        })
    );
}

//Should we delete this now ?
export async function moveFilesToOtherS3Folder(s3, folderToMove, destinationFolder) {
    let bucketName = S3_BUCKET;

    try {
        const listObjectsResponse = await s3
            .listObjects({
                Bucket: bucketName,
                Prefix: folderToMove,
                Delimiter: "/",
            })
            .promise();

        console.log("ListObjectsResponse", listObjectsResponse);
        const folderContentInfo = listObjectsResponse.Contents;
        const folderPrefix = listObjectsResponse.Prefix;

        await Promise.all(
            folderContentInfo.map(async (fileInfo) => {
                await s3
                    .copyObject({
                        Bucket: bucketName,
                        CopySource: `${bucketName}/${fileInfo.Key}`, // old file Key
                        Key: `${destinationFolder}${fileInfo.Key.replace(folderPrefix, "")}`, // new file Key
                    })
                    .promise();

                await s3
                    .deleteObject({
                        Bucket: bucketName,
                        Key: fileInfo.Key,
                    })
                    .promise();
            })
        );
    } catch (err) {
        console.error(err); // error handling
    }
}

export async function deleteFilesFromS3Folder(s3, folderToDelete) {
    let bucketName = S3_BUCKET;

    try {
        const listObjectsResponse = await s3
            .listObjects({
                Bucket: bucketName,
                Prefix: folderToDelete,
                Delimiter: "/",
            })
            .promise();

        console.log("ListObjectsResponse", listObjectsResponse);
        const folderContentInfo = listObjectsResponse.Contents;

        await Promise.all(
            folderContentInfo.map(async (fileInfo) => {
                await s3
                    .deleteObject({
                        Bucket: bucketName,
                        Key: fileInfo.Key,
                    })
                    .promise();
            })
        );
    } catch (err) {
        console.error(err); // error handling
    }
}

//  EUI tables always read numbers as strings even when converted to a number, this overcomes the issue
export const helperNumber = 10000000;
