import { EuiButtonEmpty, EuiContextMenu, EuiIcon, EuiPopover } from "@elastic/eui";
import React, { useContext } from "react";
import { useState } from "react";

import { toOptionalArrayEntry } from "../utils/optionalArray";
import { ShopContext } from "../utils/Shop";

export const PriceShowSwitcher = () => {
    const [isPopoverOpen, setPopover] = useState(false);
    const { shopId } = useContext(ShopContext);

    interface PriceShowItem {
        icon: JSX.Element;
        name: string;
        href: string;
        onClick?: () => void;
    }

    const priceShowList: PriceShowItem[] = [
        ...toOptionalArrayEntry(
            {
                icon: <EuiIcon type="eye" size="m" />,
                name: "Standaard show",
                href: `/pricelist/${shopId}`,
            },
            shopId !== "470f3f5a-e7b9-43a5-bbfd-2ffce8c161e4"
        ),
        ...toOptionalArrayEntry(
            {
                icon: <EuiIcon type="eye" size="m" />,
                name: "Extra show",
                href: `/pricelist/${shopId}/extra`,
            },
            shopId === "f155b35a-14a1-4866-867f-93fe21e9769b"
        ),
        ...toOptionalArrayEntry(
            {
                icon: <EuiIcon type="eye" size="m" />,
                name: "Horeca show",
                href: `/pricelist/${shopId}/horeca`,
            },
            !["470f3f5a-e7b9-43a5-bbfd-2ffce8c161e4", "f155b35a-14a1-4866-867f-93fe21e9769b"].includes(shopId)
        ),
        //TODO: Enable for all shops when they support the Dynamic Pricelist
        ...toOptionalArrayEntry(
            {
                icon: <EuiIcon type="eye" size="m" />,
                name: "Cannabis show",
                href: `/pricelist/${shopId}/cannabis`,
            },
            ["470f3f5a-e7b9-43a5-bbfd-2ffce8c161e4"].includes(shopId)
        ),
    ];

    const onButtonClick = () => {
        setPopover(!isPopoverOpen);
    };

    const closePopover = () => {
        setPopover(false);
    };

    const button = (
        <EuiButtonEmpty iconType="arrowDown" iconSide="right" onClick={onButtonClick}>
            Start a show
        </EuiButtonEmpty>
    );

    const panels = [
        {
            id: 0,
            title: "Kies een prijslijst show om te starten:",
            items: priceShowList,
        },
    ];

    return (
        <EuiPopover
            id="priceShowSwitcher"
            button={button}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="none"
            anchorPosition="downLeft"
        >
            <EuiContextMenu initialPanelId={0} panels={panels} />
        </EuiPopover>
    );
};
