import {
    EuiButton,
    EuiFieldNumber,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSpacer,
    EuiText,
} from "@elastic/eui";
import { Dispatch, SetStateAction } from "react";
import React from "react";
import { JsonPrice } from "utils/types";

export function PriceCombiForm({
    useExistingPrice,
    edibleJsonPrices,
    setEdibleJsonPrices,
    preRolledJsonPrices,
    setPreRolledJsonPrices,
    cannabisJsonPrices,
    setCannabisJsonPrices,
    setInternalIdPrefix,
    internalIdPrefix,
}: {
    useExistingPrice: boolean;
    edibleJsonPrices: JsonPrice[];
    setEdibleJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>;
    preRolledJsonPrices: JsonPrice[];
    setPreRolledJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>;
    cannabisJsonPrices: JsonPrice[];
    setCannabisJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>;
    setInternalIdPrefix: Dispatch<SetStateAction<string>>;
    internalIdPrefix: string;
}) {
    const setJsonPrice = (
        index: number,
        jsonPrices: JsonPrice[],
        setJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>,
        field: string,
        value: string | number | boolean
    ) => {
        const prices: JsonPrice[] = [...jsonPrices];
        prices[index][field as keyof object] = value;
        setJsonPrices(prices);
    };

    const addJsonPrice = (jsonPrices: JsonPrice[], setJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>) => {
        const prices: JsonPrice[] = [...jsonPrices];
        prices.push({
            label: "",
            quantity: 0.0,
            price: 0.0,
            active: true,
        });
        setJsonPrices(prices);
    };

    const removeJsonPrice = (jsonPrices: JsonPrice[], setJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>) => {
        if (jsonPrices.length > 0) {
            const prices: JsonPrice[] = [...jsonPrices];
            prices.pop();
            setJsonPrices(prices);
        }
    };

    const handleInternalIdPrefix = (input: string) => {
        input = input.replace(/\D/g, "");
        input = input.slice(0, 3);
        input = input.padStart(3, "0");
        setInternalIdPrefix(input);
    };

    return (
        <>
            <EuiFormRow
                label="Kassa Code Prefix"
                labelAppend={<EuiText size="m">Vul een drie-cijferige kassa code prefix in.</EuiText>}
                id="select-price"
                fullWidth
                isDisabled={useExistingPrice}
            >
                <EuiFieldNumber
                    value={internalIdPrefix}
                    onChange={(e) => setInternalIdPrefix(e.target.value)}
                    onBlur={(e) => handleInternalIdPrefix(e.target.value)}
                    fullWidth
                />
            </EuiFormRow>
            <EuiFormRow
                label="Cannabis Prijzen"
                labelAppend={<EuiText size="m">Vul de prijzen voor cannabis in.</EuiText>}
                id="select-price"
                fullWidth
                isDisabled={useExistingPrice}
            >
                <>
                    {cannabisJsonPrices.map((price, index) => (
                        <EuiFlexGroup
                            gutterSize="l"
                            direction="column"
                            style={{ borderBottom: "lightBlue 1px solid", paddingTop: "5px" }}
                            key={index}
                        >
                            <EuiFlexItem>
                                <EuiText>Hoeveelheid (in grammen)</EuiText>
                                <EuiFieldNumber
                                    disabled={useExistingPrice}
                                    step={0.5}
                                    value={price.quantity}
                                    fullWidth
                                    onChange={(e) => {
                                        e.target.value = e.target.value ? e.target.value.replace(/^0+/, "") : "0";
                                        setJsonPrice(
                                            index,
                                            cannabisJsonPrices,
                                            setCannabisJsonPrices,
                                            "quantity",
                                            e.target.value
                                        );
                                        setJsonPrice(
                                            index,
                                            cannabisJsonPrices,
                                            setCannabisJsonPrices,
                                            "label",
                                            `${e.target.value}g`
                                        );
                                    }}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiText>Prijs (in Euro's)</EuiText>
                                <EuiFieldNumber
                                    disabled={useExistingPrice}
                                    step={0.25}
                                    value={price.price}
                                    fullWidth
                                    onChange={(e) => {
                                        e.target.value = e.target.value ? e.target.value.replace(/^0+/, "") : "0";
                                        setJsonPrice(
                                            index,
                                            cannabisJsonPrices,
                                            setCannabisJsonPrices,
                                            "price",
                                            e.target.value
                                        );
                                    }}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiText>Label</EuiText>
                                <EuiFieldText
                                    disabled={true}
                                    value={price.label}
                                    fullWidth
                                    onChange={(e) =>
                                        setJsonPrice(
                                            index,
                                            cannabisJsonPrices,
                                            setCannabisJsonPrices,
                                            "label",
                                            e.target.value
                                        )
                                    }
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    ))}
                    <EuiSpacer />
                    <EuiButton
                        disabled={useExistingPrice}
                        iconType={"plus"}
                        style={{ marginRight: "5px" }}
                        onClick={() => addJsonPrice(cannabisJsonPrices, setCannabisJsonPrices)}
                    >
                        Add
                    </EuiButton>
                    <EuiButton
                        iconType={"minus"}
                        disabled={cannabisJsonPrices.length < 1 || useExistingPrice}
                        onClick={() => removeJsonPrice(cannabisJsonPrices, setCannabisJsonPrices)}
                    >
                        Remove
                    </EuiButton>
                </>
            </EuiFormRow>
            <EuiFormRow
                label="Pre-Rolled Joints Prijzen"
                labelAppend={<EuiText size="m">Vul de prijzen voor pre-rolled joints in.</EuiText>}
                id="select-price"
                fullWidth
            >
                <>
                    {preRolledJsonPrices.map((price, index) => (
                        <EuiFlexGroup
                            gutterSize="l"
                            direction="column"
                            style={{ borderBottom: "lightBlue 1px solid", paddingTop: "5px" }}
                            key={index}
                        >
                            <EuiFlexItem>
                                <EuiText>Hoeveelheid (in aantal joints)</EuiText>
                                <EuiFieldNumber
                                    disabled={useExistingPrice}
                                    step={0.01}
                                    value={price.quantity}
                                    fullWidth
                                    onChange={(e) => {
                                        e.target.value = e.target.value ? e.target.value.replace(/^0+/, "") : "0";
                                        setJsonPrice(
                                            index,
                                            preRolledJsonPrices,
                                            setPreRolledJsonPrices,
                                            "quantity",
                                            e.target.value
                                        );
                                        setJsonPrice(
                                            index,
                                            preRolledJsonPrices,
                                            setPreRolledJsonPrices,
                                            "label",
                                            `${e.target.value}j`
                                        );
                                    }}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiText>Prijs (in Euro's)</EuiText>
                                <EuiFieldNumber
                                    disabled={useExistingPrice}
                                    step={0.01}
                                    value={price.price}
                                    fullWidth
                                    onChange={(e) => {
                                        e.target.value = e.target.value ? e.target.value.replace(/^0+/, "") : "0";
                                        setJsonPrice(
                                            index,
                                            preRolledJsonPrices,
                                            setPreRolledJsonPrices,
                                            "price",
                                            e.target.value
                                        );
                                    }}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiText>Edible Prijzen</EuiText>
                                <EuiFieldText
                                    disabled={true}
                                    value={price.label}
                                    fullWidth
                                    onChange={(e) =>
                                        setJsonPrice(
                                            index,
                                            preRolledJsonPrices,
                                            setPreRolledJsonPrices,
                                            "label",
                                            e.target.value
                                        )
                                    }
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    ))}
                    <EuiSpacer />
                    <EuiButton
                        disabled={useExistingPrice}
                        iconType={"plus"}
                        style={{ marginRight: "5px" }}
                        onClick={() => addJsonPrice(preRolledJsonPrices, setPreRolledJsonPrices)}
                    >
                        Add
                    </EuiButton>
                    <EuiButton
                        iconType={"minus"}
                        disabled={preRolledJsonPrices.length < 1 || useExistingPrice}
                        onClick={() => removeJsonPrice(preRolledJsonPrices, setPreRolledJsonPrices)}
                    >
                        Remove
                    </EuiButton>
                </>
            </EuiFormRow>
            <EuiFormRow
                label="Edible Prijzen"
                labelAppend={<EuiText size="m">Vul de prijzen voor edibles in.</EuiText>}
                id="select-price"
                fullWidth
            >
                <>
                    {edibleJsonPrices.map((price, index) => (
                        <EuiFlexGroup
                            gutterSize="l"
                            direction="column"
                            style={{ borderBottom: "lightBlue 1px solid", paddingTop: "5px" }}
                            key={index}
                        >
                            <EuiFlexItem>
                                <EuiText>Hoeveelheid (in aantal edibles)</EuiText>
                                <EuiFieldNumber
                                    disabled={useExistingPrice}
                                    step={1}
                                    value={price.quantity}
                                    fullWidth
                                    onChange={(e) => {
                                        e.target.value = e.target.value ? e.target.value.replace(/^0+/, "") : "0";
                                        setJsonPrice(
                                            index,
                                            edibleJsonPrices,
                                            setEdibleJsonPrices,
                                            "quantity",
                                            e.target.value
                                        );
                                        setJsonPrice(
                                            index,
                                            edibleJsonPrices,
                                            setEdibleJsonPrices,
                                            "label",
                                            `${e.target.value}st`
                                        );
                                    }}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiText>Prijs (in Euro's)</EuiText>
                                <EuiFieldNumber
                                    disabled={useExistingPrice}
                                    step={0.25}
                                    value={price.price}
                                    fullWidth
                                    onChange={(e) =>
                                        setJsonPrice(
                                            index,
                                            edibleJsonPrices,
                                            setEdibleJsonPrices,
                                            "price",
                                            e.target.value
                                        )
                                    }
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiText>Label</EuiText>
                                <EuiFieldText
                                    disabled={true}
                                    value={price.label}
                                    fullWidth
                                    onChange={(e) => {
                                        e.target.value = e.target.value ? e.target.value.replace(/^0+/, "") : "0";
                                        setJsonPrice(
                                            index,
                                            edibleJsonPrices,
                                            setEdibleJsonPrices,
                                            "label",
                                            e.target.value
                                        );
                                    }}
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    ))}
                    <EuiSpacer />
                    <EuiButton
                        disabled={useExistingPrice}
                        iconType={"plus"}
                        style={{ marginRight: "5px" }}
                        onClick={() => addJsonPrice(edibleJsonPrices, setEdibleJsonPrices)}
                    >
                        Add
                    </EuiButton>
                    <EuiButton
                        iconType={"minus"}
                        disabled={edibleJsonPrices.length < 1 || useExistingPrice}
                        onClick={() => removeJsonPrice(edibleJsonPrices, setEdibleJsonPrices)}
                    >
                        Remove
                    </EuiButton>
                </>
            </EuiFormRow>
        </>
    );
}
