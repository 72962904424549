import {
    EuiButton,
    EuiFieldNumber,
    EuiFieldText,
    EuiFlexGroup,
    EuiForm,
    EuiFormRow,
    EuiSelect,
    EuiText,
} from "@elastic/eui";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { JsonPrice } from "utils/types";

export function ColumnPriceForm({
    tablePriceKey,
    tablePriceValue,
    labelSuffix,
    setShowModal,
    submit,
    setLabelSuffix,
    cannabisJsonPrices,
    edibleJsonPrices,
    preRolledJsonPrices,
    setCannabisJsonPrices,
    setEdibleJsonPrices,
    setPreRolledJsonPrices,
}: {
    tablePriceKey: string | undefined;
    tablePriceValue: number | undefined;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    labelSuffix: string;
    submit: () => void;
    setLabelSuffix: Dispatch<SetStateAction<string>>;
    setCannabisJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>;
    setEdibleJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>;
    setPreRolledJsonPrices: Dispatch<SetStateAction<JsonPrice[]>>;
    cannabisJsonPrices: JsonPrice[];
    edibleJsonPrices: JsonPrice[];
    preRolledJsonPrices: JsonPrice[];
}) {
    const [readyToSubmit, setReadyToSubmit] = useState(false);

    const [price, setPrice] = useState<JsonPrice>(
        tablePriceKey && tablePriceValue
            ? {
                  quantity: Number(
                      tablePriceKey.split("g")[0].split("j")[0].split("e")[0].split("st")[0].split(" ")[0]
                  ),
                  price: tablePriceValue,
                  label: tablePriceKey,
                  active: true,
              }
            : {
                  quantity: 0,
                  price: 0,
                  label: "0g",
                  active: true,
              }
    );

    const [ogPrice] = useState<JsonPrice>(
        tablePriceKey && tablePriceValue
            ? {
                  quantity: Number(
                      tablePriceKey.split("g")[0].split("j")[0].split("e")[0].split("st")[0].split(" ")[0]
                  ),
                  price: tablePriceValue,
                  label: tablePriceKey,
                  active: true,
              }
            : {
                  quantity: 0,
                  price: 0,
                  label: "0g",
                  active: true,
              }
    );

    const options = [
        {
            value: "g",
            text: "Cannabis",
        },
        {
            value: "e",
            text: "Edible",
        },
        {
            value: "j",
            text: "Pre-Rolled Joints",
        },
    ];

    useEffect(() => {
        if (readyToSubmit) {
            submit();
        }
    }, [readyToSubmit, submit]);

    const setJsonPrice = () => {
        const jsonPrices: JsonPrice[] = determineJsonPrices()!;
        const setJsonPrices: Dispatch<SetStateAction<JsonPrice[]>> = determineSetJsonPrices()!;

        const prices: JsonPrice[] = [...jsonPrices];
        const jsonPrice = prices.find((p) => p.label.toString() === ogPrice.label.toString());
        console.log(jsonPrice);
        if (jsonPrice) {
            prices[prices.indexOf(jsonPrice)] = price;
            setJsonPrices(prices);
            setReadyToSubmit(true);
        }
    };

    const addJsonPrice = () => {
        const jsonPrices: JsonPrice[] = determineJsonPrices()!;
        const setJsonPrices: Dispatch<SetStateAction<JsonPrice[]>> = determineSetJsonPrices()!;

        const prices: JsonPrice[] = [...jsonPrices];
        prices.push(price);
        setJsonPrices(prices);
        setReadyToSubmit(true);
    };

    const removeJsonPrice = () => {
        const jsonPrices: JsonPrice[] = determineJsonPrices()!;
        const setJsonPrices: Dispatch<SetStateAction<JsonPrice[]>> = determineSetJsonPrices()!;

        if (jsonPrices.length > 0) {
            const prices: JsonPrice[] = [...jsonPrices];
            const jsonPrice = prices.find((p) => p.label.toString() === price.label.toString());
            if (jsonPrice) {
                delete prices[prices.indexOf(jsonPrice)];
                setJsonPrices(prices.filter((p) => p !== null));
                setReadyToSubmit(true);
            }
        }
    };

    function determineJsonPrices() {
        switch (labelSuffix) {
            case "g":
                return cannabisJsonPrices;
            case "e":
                return edibleJsonPrices;
            case "j":
                return preRolledJsonPrices;
            default:
                break;
        }
    }

    function determineSetJsonPrices() {
        switch (labelSuffix) {
            case "g":
                return setCannabisJsonPrices;
            case "e":
                return setEdibleJsonPrices;
            case "j":
                return setPreRolledJsonPrices;
            default:
                break;
        }
    }

    return (
        <EuiForm>
            <EuiFormRow
                label="Type"
                labelAppend={
                    <EuiText size="s">Selecteer het type product (dit bepaalt de eenheid van de hoeveelheid).</EuiText>
                }
                id="select-price"
                fullWidth
            >
                <EuiSelect
                    options={options}
                    value={labelSuffix}
                    onChange={(e) => {
                        setLabelSuffix(e.target.value);
                    }}
                />
            </EuiFormRow>
            <EuiFormRow
                label="Hoeveelheid"
                labelAppend={<EuiText size="s">Vul de hoeveelheid in (in grammen, joints of aantal stuks).</EuiText>}
                id="select-price"
                fullWidth
            >
                <EuiFieldNumber
                    step={0.5}
                    value={price.quantity}
                    fullWidth
                    onChange={(e) => {
                        const newPrice = { ...price };
                        e.target.value = e.target.value ? e.target.value.replace(/^0+/, "") : "0";
                        newPrice.quantity = e.target.value ? Number(e.target.value) : 0;
                        newPrice.label = e.target.value ? `${e.target.value}${labelSuffix}` : `0${labelSuffix}`;
                        setPrice(newPrice);
                    }}
                />
            </EuiFormRow>
            <EuiFormRow
                label="Prijs"
                labelAppend={<EuiText size="s">Vul de prijs in (in euro).</EuiText>}
                id="select-price"
                fullWidth
            >
                <EuiFieldNumber
                    step={0.25}
                    value={price.price}
                    fullWidth
                    onChange={(e) => {
                        const newPrice = { ...price };
                        e.target.value = e.target.value ? e.target.value.replace(/^0+/, "") : "0";
                        newPrice.price = Number(e.target.value);
                        setPrice(newPrice);
                    }}
                />
            </EuiFormRow>
            <EuiFormRow label="Label" id="select-price" fullWidth>
                <EuiFieldText size={"s"} value={price.label} fullWidth disabled />
            </EuiFormRow>

            <EuiFlexGroup direction="row" justifyContent="spaceBetween">
                {tablePriceKey ? (
                    <>
                        <EuiButton fill size={"s"} onClick={() => setJsonPrice()}>
                            Bewaar
                        </EuiButton>
                        <EuiButton fill size={"s"} onClick={() => removeJsonPrice()}>
                            Verwijder
                        </EuiButton>
                    </>
                ) : (
                    <EuiButton fill size={"s"} onClick={() => addJsonPrice()}>
                        Voeg toe
                    </EuiButton>
                )}
                <EuiButton fill size={"s"} onClick={() => setShowModal(false)}>
                    Annuleer
                </EuiButton>
            </EuiFlexGroup>
        </EuiForm>
    );
}
