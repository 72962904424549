import { RoundAbout69CannabisSettings } from "pages/dynamicPricelistSettings/cannabis/RoundAbout69CannabisSettings";
import { roundaboutHorecaSettings } from "pages/dynamicPricelistSettings/horeca/RoundAbout69HorecaSettings";

import {
    DeSteegPriceModel,
    deSteegCannabisSettings,
} from "../pages/dynamicPricelistSettings/cannabis/DeSteegCannabisSettings";
import { easyGoingHorecaSettings } from "../pages/dynamicPricelistSettings/horeca/EasyGoingHorecaSettings";
import { Price, PricelistTableRow, RowType } from "./types";

export const generateTableRows = <T>(activePrices: Price<T>[], maxRowsPerColumn: number): PricelistTableRow<T>[] => {
    const tableRows: PricelistTableRow<T>[] = [];
    let columnCounter = 1;
    let rowCounter = 1;
    let previousCategory: string | null = null;

    const pushRow = (text: string, price: Price<T> | null, type: RowType, color: string) => {
        tableRows.push({ text, price, type, row: rowCounter, column: columnCounter, category_color: color });
        rowCounter++;
    };

    const pushTitleRow = (price: Price<T>) => pushRow(price.category_name, null, RowType.Title, price.category_color);
    const pushNameRow = (price: Price<T>) => pushRow(price.kind_name ?? price.product_name, price, RowType.DataRow, "");
    const pushBlankRow = () => pushRow("", null, RowType.Blank, "");
    const pushLegendRow = () => pushRow("", null, RowType.Legend, "");

    const resetRowCounter = () => {
        rowCounter = 1;
        columnCounter++;
    };

    const pushFirstItemInColumn = (price: Price<T>) => {
        pushTitleRow(price);
        pushNameRow(price);
        previousCategory = price.category_name;
    };

    for (let item of activePrices) {
        if (rowCounter === 1) {
            pushFirstItemInColumn(item);
            continue;
        }

        if (item.category_name === previousCategory) {
            pushNameRow(item);
            if (rowCounter > maxRowsPerColumn) {
                resetRowCounter();
            }
        } else {
            const remainingRows = maxRowsPerColumn - rowCounter;
            if (remainingRows >= 2) {
                pushBlankRow();
                pushTitleRow(item);
                pushNameRow(item);
                previousCategory = item.category_name;
            } else if (remainingRows === 1) {
                pushBlankRow();
                resetRowCounter();
                pushFirstItemInColumn(item);
            } else {
                resetRowCounter();
                pushFirstItemInColumn(item);
            }
        }
    }

    while (rowCounter <= maxRowsPerColumn) {
        if (rowCounter === maxRowsPerColumn) {
            pushLegendRow();
        } else {
            pushBlankRow();
        }
    }

    return tableRows;
};

export const separateTableRowsInColumns = <T>(items: PricelistTableRow<T>[]) => {
    // Find the maximum column value
    const maxColumn = items.reduce((max, item) => Math.max(max, item.column), 0);

    // Initialize an array to hold the separated arrays
    const separatedArrays: PricelistTableRow<T>[][] = new Array(maxColumn).fill(null).map(() => []);

    // Separate items into arrays based on column value
    items.forEach((item: PricelistTableRow<T>) => {
        separatedArrays[item.column - 1].push(item);
    });

    return separatedArrays;
};

export const generateTableRowsAndColumns = <T>(activePrices: Price<T>[], maxRowsPerColumn: number) => {
    const tableRows = generateTableRows(activePrices, maxRowsPerColumn);
    return separateTableRowsInColumns(tableRows);
};

export const horecaDynamicPricelistSettingsLoader = (shopId: string) => {
    switch (shopId) {
        case "ddd70853-5e9d-45ff-82b3-24c9d81d4e32":
            return easyGoingHorecaSettings;
        case "f155b35a-14a1-4866-867f-93fe21e9769b":
            return roundaboutHorecaSettings;
        default:
            return easyGoingHorecaSettings;
    }
};

export const cannabisDynamicPricelistSettingsLoader = (shopId: string) => {
    switch (shopId) {
        case "470f3f5a-e7b9-43a5-bbfd-2ffce8c161e4":
            return deSteegCannabisSettings;
        case "f155b35a-14a1-4866-867f-93fe21e9769b":
            return RoundAbout69CannabisSettings;
        default:
            return deSteegCannabisSettings;
    }
};

export function getTotalHeightInVh(logoHeaderHeight: number, logoHeaderPadding: number, tableColumnPadding: number) {
    return 100 - logoHeaderHeight - logoHeaderPadding * 2 - tableColumnPadding * 2;
}

export const pricePerGram = (jsonPrices: Price<DeSteegPriceModel>["json_prices"]) => {
    const { price = 0, quantity = 1 } = jsonPrices?.[0] || {};
    return price / quantity;
};

export const sortByCategoryAndLowestPrice = (a: Price<DeSteegPriceModel>, b: Price<DeSteegPriceModel>) => {
    // First compare by category_pricelist_row
    const categoryComparison = a.category_pricelist_row - b.category_pricelist_row;
    if (categoryComparison !== 0) {
        return categoryComparison;
    }

    const priceComparison = pricePerGram(a.json_prices) - pricePerGram(b.json_prices);
    if (priceComparison !== 0) {
        return priceComparison;
    }

    if (a.kind_name < b.kind_name) {
        return -1;
    }
    if (a.kind_name > b.kind_name) {
        return 1;
    }

    return 0;
};

export const filterActivePrices = <T>(prices: Price<T>[], excludedCategories: string[]) => {
    return prices
        ? prices.filter((price: Price<T>) => price.active && !excludedCategories.includes(price.category_name))
        : [];
};
