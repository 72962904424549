import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Badge } from "./Badge";
import { mississippiBadges } from "./PriceRowStyling";

export const PriceLegendNew = () => {
    const stylesRow = mississippiBadges;

    return (
        <div className="legend">
            <div className="legendContainer">
                <EuiFlexGroup
                    gutterSize="s"
                    justifyContent="spaceBetween"
                    alignItems="center"
                    style={{ color: "white" }}
                >
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup gutterSize="m" direction="row" alignItems="center">
                            <FontAwesomeIcon
                                style={{
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    marginLeft: "0.5vw",
                                    fontSize: "1.75vw",
                                }}
                                icon={faStar}
                                color={"#d1cb28"}
                            />
                            <label style={{ marginLeft: "0.5vw" }}>New</label>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup gutterSize="m" alignItems="center">
                            <Badge letter={"c"} styles={stylesRow} />
                            <div>CBD</div>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup gutterSize="m" alignItems="center">
                            <Badge letter={"i"} styles={stylesRow} />
                            <div>Indica</div>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup gutterSize="m" alignItems="center">
                            <Badge letter={"s"} styles={stylesRow} />
                            <div>Sativa</div>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup gutterSize="m" alignItems="center">
                            <Badge letter={"h"} styles={stylesRow} />
                            <div>Hybrid</div>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </div>
        </div>
    );
};
