import { css } from "@emotion/react";

import { COLORS } from "../stylesheets/emotion/vars";

export const priceGridStyles = css`
    .priceGrid {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
    }

    .priceLabel {
        //flex: 1;
    }

    .priceCard {
        padding: 2px 2px 2px 4px;
        border-radius: 8px;
        text-align: left;
        cursor: pointer;
        min-width: 60px;
        transition: 0.1s ease-in-out;
        display: flex;
    }

    .priceCardHeader {
        display: flex;
        align-items: center;
        justify-content: left; /* Ensures max space between label & icon */
        gap: 5px;
        width: 100%;
    }

    .priceCard:hover {
        background-color: ${COLORS.lightestPrimary};
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
    }

    .priceTableEditIcon {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    .priceCard:hover .priceTableEditIcon {
        opacity: 1;
    }
`;
