import { JsonPrice, Price } from "./types";
import { convertToRoundPrice } from "./Utils";

const extractNumericValue = (label: string) => parseFloat(label);

export const generateInternalProductId = (internalIdPrefix: string, allPrices: JsonPrice[][]): string => {
    const parts = [`${internalIdPrefix}:`];

    allPrices
        .flat()
        .sort((a, b) => extractNumericValue(a.label) - extractNumericValue(b.label))
        .forEach((price) => {
            parts.push(`${price.label} voor ${convertToRoundPrice(price.price)} -`);
        });

    return parts.join(" ");
};

export const findExistingPrice = (prices: Price[], internalProductId: string): Price | undefined => {
    return prices.find((price) => {
        return price.internal_product_id === internalProductId.substring(0, internalProductId.length - 3);
    });
};
