import "./Forms.scss";

import {
    EuiButton,
    EuiCallOut,
    EuiFieldNumber,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiSelect,
    EuiSpacer,
    EuiSwitch,
    EuiText,
} from "@elastic/eui";
import React, { useContext, useEffect, useState } from "react";

import client from "../api/Client";
import API_URL from "../Constants";
import { ShopContext } from "../utils/Shop";
import { convertToRoundPrice } from "../utils/Utils";

function HorecaFormNew({ product }: { product: object | undefined }) {
    const shopContext = useContext(ShopContext);

    const [redirectToListEnabled, setRedirectToListEnabled] = useState(true);
    const [prices, setPrices] = useState([]);
    const [priceOptions, setPriceOptions] = useState([]);
    const [pricesLoaded, setPricesLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categoriesLoaded, setCategoriesLoaded] = useState(false);
    const [products, setProducts] = useState([]);
    const [productsLoaded, setProductsLoaded] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState(product ? product.price_id : "-1");
    const [selectedCategory, setSelectedCategory] = useState(product ? product.category_id : "-1");
    const [selectedProduct, setSelectedProduct] = useState(product ? product.kind_id : "-1");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [messageHelp, setMessageHelp] = useState<string | undefined>(undefined);
    const [errors, setErrors] = useState(false);

    const [checkedNew, setCheckedNew] = useState(false);

    const [useExistingPrice, setUseExistingPrice] = useState(product ? true : false);
    const [existingPrice, setExistingPrice] = useState(undefined);
    const [piecePrice, setPiecePrice] = useState(0);
    const [internalIdPrefix, setInternalIdPrefix] = useState<string>("000");

    useEffect(() => {
        setCheckedNew(
            !localStorage.getItem("markPriceAsNew")
                ? true
                : localStorage.getItem("markPriceAsNew") === "true"
                ? true
                : false
        );

        setRedirectToListEnabled(
            !localStorage.getItem("redirectToPriceList")
                ? false
                : localStorage.getItem("redirectToPriceList") === "true"
                ? true
                : false
        );

        client.get(`${API_URL}/v1/prices?skip=0&limit=1000&sort=internal_product_id`).then((result) => {
            // prepare stuff for EuiSuperSelect
            const priceOptions = result.data
                .filter((price) => price.shop_group_id === "03ca4eaa-ed3e-461e-9c4c-048d043da406")
                .filter(
                    (price) =>
                        (!price.cannabis || price.cannabis.length < 1) &&
                        (!price.edible || price.edible.length < 1) &&
                        (!price.pre_rolled_joints || price.pre_rolled_joints.length < 1)
                )
                .map((price) => ({
                    text: price.internal_product_id,
                    value: price.id,
                }));
            priceOptions.unshift({ text: "", value: "-1" });
            setPrices(
                result.data.filter(
                    (price) =>
                        (!price.cannabis || price.cannabis.length < 1) &&
                        (!price.edible || price.edible.length < 1) &&
                        (!price.pre_rolled_joints || price.pre_rolled_joints.length < 1)
                )
            );
            setPriceOptions(priceOptions);
            setPricesLoaded(true);
            setSelectedPrice("-1");

            if (product) {
                setSelectedPrice(product.price_id);
                setCheckedNew(product.new);
            }
        });

        client.get(`${API_URL}/v1/categories?skip=0&limit=1000&sort=name`).then((result) => {
            const filterCategories = result.data.filter(
                (value) => value.shop_id === shopContext.shopId && !value.cannabis
            );
            let categories = filterCategories.map((category) => {
                category.label = category.name;
                category.value = category.id;
                return category;
            });
            // Add blanco first option
            categories.unshift({ label: "", value: "-1" });

            setCategories(categories);
            setCategoriesLoaded(true);
            setSelectedCategory(product ? product.category_id : "-1");
        });

        client.get(`${API_URL}/v1/products?skip=0&limit=1000&sort=name`).then((result) => {
            const products = result.data.map((product) => {
                product.label = product.name;
                product.value = product.id;
                return product;
            });
            // Add blanco first option
            products.unshift({ label: "", value: "-1" });

            setProducts(products);
            setProductsLoaded(true);
            setSelectedProduct(product ? product.product_id : "-1");
        });
    }, [product, shopContext.shopId]);

    const handleSubmit = async () => {
        if (selectedProduct === "-1" || selectedCategory === "-1") {
            setMessage("Error");
            setMessageHelp(
                "Het form is niet compleet ingevuld. Prijs, categorie en product-soort moeten ingevuld zijn."
            );
            setErrors(true);
            setTimeout(() => {
                setMessage(undefined);
                setMessageHelp(undefined);
                setErrors(false);
            }, 3000);
            return;
        }

        let payload = {
            shop_id: shopContext.shopId,
            new: checkedNew,
            price_id: selectedPrice,
            product_id: selectedProduct,
            category_id: selectedCategory,
            kind_id: null,
            use_half: false,
            use_one: false,
            use_two_five: false,
            use_five: false,
            use_joint: false,
            use_piece: true,
        };
        if (!product) {
            payload["active"] = true;
        } else {
            payload["active"] = product["active" as keyof object];
        }

        setLoading(true);

        const internal_product_id = `${internalIdPrefix}: ${convertToRoundPrice(piecePrice)}`;

        if (product) {
            if (
                !useExistingPrice ||
                (useExistingPrice && existingPrice && existingPrice.internal_product_id !== internal_product_id)
            ) {
                const pricePayload = {
                    piece: piecePrice,
                    internal_product_id: internal_product_id,
                    shop_group_id: "03ca4eaa-ed3e-461e-9c4c-048d043da406",
                };

                await client.post(`/v1/prices`, pricePayload).then((priceResult) => {
                    payload.price_id = priceResult.data.id;
                });
            }
            client
                .put(`/v1/shops-to-prices/${product.id}`, payload) // Todo: probably jsonify needed
                .then(() => {
                    setMessage(`Product is opgeslagen. Redirecting...`);
                    setMessageHelp(undefined);
                    setErrors(false);
                    if (!redirectToListEnabled) {
                        setTimeout(() => {
                            window.location.replace("/products/horeca");
                        }, 3000);
                    } else {
                        resetFormState();
                        setTimeout(() => {
                            setMessage(undefined);
                            setMessageHelp(undefined);
                            setErrors(false);
                        }, 3000);
                    }
                })
                .catch((result) => {
                    if (result.message === "Request failed with status code 409") {
                        setMessage("Error");
                        setMessageHelp("U heeft dit product al toegevoegd aan deze shop!");
                        setErrors(true);
                        setTimeout(() => {
                            setMessage(undefined);
                            setMessageHelp(undefined);
                            setErrors(false);
                        }, 3000);
                    } else {
                        setMessage("Error");
                        setMessageHelp("Een form veld klopt niet.");
                        setErrors(true);
                        setTimeout(() => {
                            setMessage(undefined);
                            setMessageHelp(undefined);
                            setErrors(false);
                        }, 3000);
                    }
                });
        } else {
            if (
                !useExistingPrice ||
                (useExistingPrice && existingPrice && existingPrice.internal_product_id !== internal_product_id)
            ) {
                const pricePayload = {
                    piece: piecePrice,
                    internal_product_id: internal_product_id,
                    shop_group_id: "03ca4eaa-ed3e-461e-9c4c-048d043da406",
                };

                await client.post(`/v1/prices`, pricePayload).then((priceResult) => {
                    payload.price_id = priceResult.data.id;
                });
            }

            client
                .post(`/v1/shops-to-prices`, payload)
                .then(() => {
                    setMessage(`Product is toegevoegd.`);
                    setMessageHelp(undefined);
                    setErrors(false);
                    if (!redirectToListEnabled) {
                        setTimeout(() => {
                            window.location.replace("/products/horeca");
                        }, 3000);
                    } else {
                        resetFormState();
                        setTimeout(() => {
                            setMessage(undefined);
                            setMessageHelp(undefined);
                            setErrors(false);
                        }, 3000);
                    }
                })
                .catch((result) => {
                    if (result.message === "Request failed with status code 409") {
                        setMessage("Error");
                        setMessageHelp("U heeft dit product al toegevoegd aan deze shop!");
                        setErrors(true);
                        setTimeout(() => {
                            setMessage(undefined);
                            setMessageHelp(undefined);
                            setErrors(false);
                        }, 3000);
                    } else {
                        setMessage("Error");
                        setMessageHelp("Een form veld klopt niet.");
                        setErrors(true);
                        setTimeout(() => {
                            setMessage(undefined);
                            setMessageHelp(undefined);
                            setErrors(false);
                        }, 3000);
                    }
                });
        }
    };

    const resetFormState = () => {
        setSelectedProduct("-1");
        setSelectedPrice("-1");
        setSelectedCategory("-1");
        setPiecePrice(0);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            console.log("Enter key pressed; submitting form");
            handleSubmit(event);
        }
    };

    function setSelectedBasePrice(priceId) {
        setSelectedPrice(priceId);
        if (priceId === "-1") {
            return;
        }
        const price = prices.find((p) => p.id === priceId)!;
        setExistingPrice(price);
        setInternalIdPrefix(price.internal_product_id.substring(0, 3));
        setPiecePrice(price.piece);
    }

    const handleInternalIdPrefix = (input: string) => {
        input = input.replace(/\D/g, "");
        input = input.slice(0, 3);
        input = input.padStart(3, "0");
        setInternalIdPrefix(input);
    };

    return (
        <EuiForm>
            {message && (
                <>
                    <EuiCallOut
                        title={message}
                        color={errors ? "danger" : "primary"}
                        iconType={errors ? "alert" : "notebookApp"}
                    >
                        <p>{messageHelp}</p>
                    </EuiCallOut>
                    <EuiSpacer />
                </>
            )}

            {!product && (
                <>
                    <EuiSwitch
                        label="Blijf op deze pagina na toevoegen"
                        checked={redirectToListEnabled}
                        onChange={(e) => {
                            setRedirectToListEnabled(e.target.checked);
                            localStorage.setItem("redirectToPriceList", e.target.checked.toString());
                        }}
                    />
                    <EuiSpacer />
                </>
            )}

            <EuiSwitch
                label="Publiceer product met label 'NEW'"
                checked={checkedNew}
                onChange={(e) => {
                    setCheckedNew(e.target.checked);
                    localStorage.setItem("markPriceAsNew", e.target.checked.toString());
                }}
            />
            <EuiSpacer />

            <EuiFormRow
                label="Categorie"
                labelAppend={<EuiText size="m">Selecteer een categorie</EuiText>}
                id="select-category"
                fullWidth
            >
                <EuiSelect
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    options={categories}
                    isLoading={!categoriesLoaded}
                    autoFocus={true}
                    onKeyDown={handleKeyDown}
                    fullWidth
                />
            </EuiFormRow>

            <EuiFormRow
                label="Product"
                labelAppend={<EuiText size="m">Selecteer een horeca product</EuiText>}
                id="select-product"
                fullWidth
            >
                <EuiSelect
                    value={selectedProduct}
                    onChange={(e) => setSelectedProduct(e.target.value)}
                    options={products}
                    isLoading={!productsLoaded}
                    onKeyDown={handleKeyDown}
                    fullWidth
                />
            </EuiFormRow>

            {!product && (
                <EuiSwitch
                    label="Bestaande kassa code als basis gebruiken?"
                    checked={!!useExistingPrice}
                    onChange={(e) => setUseExistingPrice(e.target.checked)}
                />
            )}

            <EuiSpacer />

            {useExistingPrice && (
                <EuiFormRow
                    label="Prijs"
                    labelAppend={<EuiText size="m">Selecteer een kassa code</EuiText>}
                    id="select-price"
                    fullWidth
                >
                    <EuiSelect
                        value={selectedPrice}
                        onChange={(e) => setSelectedBasePrice(e.target.value)}
                        options={priceOptions}
                        isLoading={!pricesLoaded}
                        autoFocus={true}
                        onKeyDown={handleKeyDown}
                        fullWidth
                    />
                </EuiFormRow>
            )}

            <EuiFormRow
                label="Kassa Code Prefix"
                labelAppend={<EuiText size="m">Vul een drie-cijferige kassa code prefix in.</EuiText>}
                id="select-price"
                fullWidth
                isDisabled={useExistingPrice}
            >
                <EuiFieldNumber
                    value={internalIdPrefix}
                    onChange={(e) => setInternalIdPrefix(e.target.value)}
                    onBlur={(e) => handleInternalIdPrefix(e.target.value)}
                    fullWidth
                />
            </EuiFormRow>

            <EuiFormRow
                label="Prijs"
                labelAppend={<EuiText size="m">Vul prijs in</EuiText>}
                id="select-price"
                fullWidth
            >
                <EuiFieldNumber
                    step={0.5}
                    value={piecePrice}
                    disabled={useExistingPrice}
                    fullWidth
                    onChange={(e) => {
                        e.target.value = e.target.value ? e.target.value.replace(/^0+/, "") : "0";
                        setPiecePrice(e.target.value ? Number(e.target.value) : 0);
                    }}
                />
            </EuiFormRow>

            <EuiFormRow>
                <EuiFlexGroup>
                    {!product && (
                        <EuiFlexItem>
                            {" "}
                            <EuiButton fill color="danger" onClick={resetFormState} disabled={false}>
                                Reset
                            </EuiButton>
                        </EuiFlexItem>
                    )}
                    <EuiFlexItem>
                        <EuiButton
                            fill
                            onClick={handleSubmit}
                            disabled={
                                selectedProduct === "-1" ||
                                selectedCategory === "-1" ||
                                ((useExistingPrice || product) && selectedPrice === "-1")
                            }
                            type="submit"
                            isLoading={loading}
                        >
                            {product ? "Opslaan" : "Toevoegen"}
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>
        </EuiForm>
    );
}

export default HorecaFormNew;
