import {
    EuiFlexGroup,
    EuiLink,
    EuiPage,
    EuiPageSection,
    EuiPanel,
    EuiSpacer,
    EuiTab,
    EuiTabs,
    EuiTitle,
} from "@elastic/eui";
import CannabisFormNew from "components/CannabisFormNew";
import HorecaFormNew from "components/HorecaFormNew";
import React from "react";
import { Link } from "react-router-dom";
import { ShopContext } from "utils/Shop";

import client from "../api/Client";
import CannabisForm from "../components/CannabisForm";
import HorecaForm from "../components/HorecaForm";
import API_URL from "../Constants";
import { capitalizeFirstLetter } from "../utils/Utils";

const tabs = [
    {
        id: "cannabis",
        name: "Cannabis",
        href: "/products/new-product/cannabis",
        disabled: false,
    },
    {
        id: "horeca",
        name: "Horeca",
        href: "/products/new-product/horeca",
        disabled: false,
    },
];

class NewProduct extends React.Component {
    static contextType = ShopContext;

    constructor(props) {
        super(props);

        this.state = {
            allData: [],
            loading: true,
            errors: null,
            product: undefined,
            selectedTabId: this.props.addCannabis ? "cannabis" : "horeca",
            searchIsOpen: false,
            preselectedWiet: undefined,
        };
    }

    componentDidMount() {
        console.log(this.props);
        if (this.props.match.params.id) {
            client.get(`${API_URL}/v1/shops-to-prices/${this.props.match.params.id}`).then((result) => {
                this.setState({
                    product: result.data,
                    loading: false,
                });
            });
        } else if (this.props.location.search.includes("?selectedWiet=")) {
            const search = this.props.location.search.replace("?selectedWiet=", "");
            this.setState({
                loading: false,
                preselectedWiet: search,
            });
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    setSelectedTab = (id) => {
        this.setState({ selectedTabId: id });
    };

    render() {
        const currentShop = this.context;
        const { selectedTabId, loading, product } = this.state;

        const renderTabs = () => {
            return tabs.map((tab, index) => (
                <Link to={tab.href} key={index}>
                    <EuiTab
                        onClick={() => this.setSelectedTab(tab.id)}
                        isSelected={tab.id === selectedTabId}
                        key={index}
                    >
                        {tab.name}
                    </EuiTab>
                </Link>
            ));
        };

        return (
            <EuiPage>
                <EuiPageSection>
                    {!product && <EuiTabs>{renderTabs()}</EuiTabs>}
                    <EuiPanel paddingSize="l">
                        <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
                            <EuiTitle size="l">
                                <h1>
                                    {capitalizeFirstLetter(selectedTabId)} product {product ? "bewerken" : "toevoegen"}
                                </h1>
                            </EuiTitle>
                            <EuiLink href={"/prices"}>Alle Prijzen Bekijken</EuiLink>
                        </EuiFlexGroup>
                        <EuiSpacer />

                        <div>
                            {selectedTabId === "cannabis" && !loading && (
                                <>
                                    {currentShop.shopId === "f155b35a-14a1-4866-867f-93fe21e9769b" ? (
                                        <CannabisFormNew
                                            product={this.state.product}
                                            preselectedWeed={this.state.preselectedWiet}
                                        />
                                    ) : (
                                        <CannabisForm
                                            product={this.state.product}
                                            preselectedWiet={this.state.preselectedWiet}
                                        />
                                    )}
                                </>
                            )}
                            {selectedTabId === "horeca" && !loading && (
                                <>
                                    {currentShop.shopId === "f155b35a-14a1-4866-867f-93fe21e9769b" ? (
                                        <HorecaFormNew product={this.state.product} />
                                    ) : (
                                        <HorecaForm product={this.state.product} />
                                    )}
                                </>
                            )}
                        </div>
                    </EuiPanel>
                </EuiPageSection>
            </EuiPage>
        );
    }
}

export default NewProduct;
