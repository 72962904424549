import axios from "axios";

import API_URL from "../Constants";
import { resetAppState } from "../utils/Utils";

const token =
    localStorage.getItem("appState") && localStorage.getItem("appState").includes("token")
        ? JSON.parse(localStorage.getItem("appState"))["profile"]["token"]
        : undefined;

const client = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
    // withCredentials: true,
});

client.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        if (error.response.status === 403) {
            console.log("Session expired resetting local storage ");
            resetAppState();
            window.location.href = "/login";
        }
        if (error.response.status === 400) {
            console.log(error.response);
            console.log(error.config.url);
            console.log("Whoot");
            if (error.config.url.includes("/v1/prices")) {
                alert("Duplicate price relation");
                window.location.href = "/products/cannabis";
            }
        }
        return Promise.reject(error);
    }
);

export function setUser(token) {
    if (token) {
        client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
}

function lookupData(response) {
    return response.data;
}

function axiosFetch(path, options = {}, headers = {}, showErrorDialog = true, result = true) {
    // preset the config with the relative URL and a GET type.
    // presets can be overridden with `options`.
    return client({ url: path, method: "GET", ...options })
        .then((res) => res.data)
        .catch((err) => {
            if (showErrorDialog) {
                setTimeout(() => {
                    throw err;
                }, 250);
            }
            throw err;
        });
}

export function catchErrorStatus(promise, status, callback) {
    return promise.catch((err) => {
        if (err.response && err.response.status === status) {
            callback(err.response.data);
        } else {
            throw err;
        }
    });
}

export function postPutJson(path, body, method, showErrorDialog = true, result = true) {
    return axiosFetch(path, { method: method, data: body }, {}, showErrorDialog, result);
}

export function get_shops(cb) {
    return client.get("/v1/shops?limit=999").then(lookupData).then(cb);
}

export function get_tables(cb) {
    return client.get("/v1/tables?limit=999").then(lookupData).then(cb);
}

export function get_all_flavors(cb) {
    return client.get(`v1/flavors?limit=999`).then(lookupData).then(cb);
}

export function get_all_strains(cb) {
    return client.get(`v1/strains?limit=999`).then(lookupData).then(cb);
}

export function get_all_tags(cb) {
    return client.get(`v1/tags?limit=999`).then(lookupData).then(cb);
}

export function get_all_resources_by_type(type, shopId, cb) {
    const url = shopId ? `v1/${type}?limit=999&filter=shop_id%3A${shopId}` : `v1/${type}?limit=999`;
    return client.get(url).then(lookupData).then(cb);
}

export function get_resource_by_id(type, id, cb) {
    return client.get(`/v1/${type}/${id}`).then(lookupData).then(cb);
}

export function get_resource_is_deletable(type, id, cb) {
    return client.get(`/v1/${type}/is-deletable/${id}`).then(lookupData).then(cb);
}

export function get_all_products_generic(table, search, cb) {
    return client.get(`/v1/${table}?limit=999`).then(lookupData).then(cb);
}

export function get_product_by_id_generic(table, id, cb) {
    return client.get(`/v1/${table}/${id}`).then(lookupData).then(cb);
}

export function get_shop_by_id(id, cb) {
    return client.get(`/v1/shops/${id}`).then(lookupData).then(cb);
}

export function startForm(formKey, userInputs, shopId) {
    console.log("Userinputs", userInputs);
    const url = shopId ? `/v1/forms/${formKey}?shop_id=${shopId}` : `/v1/forms/${formKey}`;
    return postPutJson(url, userInputs, "post", false, true);
}

export default client;
