/*
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import { EuiIcon, EuiPage, EuiPageSection } from "@elastic/eui";
import client from "api/Client";
import DropDownActions from "components/DropDownActions";
import Explain from "components/Explain";
import ConfirmationDialog from "components/modals/ConfirmationDialog";
import { KindsTable, initialKindsFilterAndSort, initialKindsTableSettings } from "components/tables/KindTable";
import React from "react";
import { stop } from "utils/Utils";

import { ShopContext } from "../utils/Shop";
import { KindForTable } from "../utils/types";
import { actionOptions, editOption } from "../validations/ProductsAndKinds";

interface IProps {}

interface IState {
    confirmationDialogOpen: boolean;
    confirmationDialogAction: (e: React.MouseEvent) => void;
    confirm: () => void;
    confirmationDialogQuestion: string;
    showExplanation: boolean;
}

class KindsPage extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            confirmationDialogOpen: false,
            confirmationDialogAction: () => this,
            confirm: () => this,
            confirmationDialogQuestion: "",
            showExplanation: false,
        };
    }

    cancelConfirmation = () => this.setState({ confirmationDialogOpen: false });

    confirmation = (question: string, action: (e: React.MouseEvent) => void) =>
        this.setState({
            confirmationDialogOpen: true,
            confirmationDialogQuestion: question,
            confirmationDialogAction: (e: React.MouseEvent) => {
                this.cancelConfirmation();
                action(e);
            },
        });

    handleViewKind = (kind: KindForTable) => (e: React.MouseEvent) => {
        window.location.replace(`/view/cannabis/${kind.id}`);
        stop(e);
    };

    handleDeleteKind = (kind: KindForTable) => () => {
        this.confirmation("Weet je zeker dat je de product wil wissen?", () =>
            client.delete(`/v1/kinds/${kind.id}`).then(() => {
                console.log("Kind deleted");
                window.location.reload();
            })
        );
    };

    renderActions = (kind: KindForTable) => {
        if (JSON.parse(localStorage.getItem("appState")!)["profile"]["email"] === "theroundabout69@gmail.com") {
            return (
                <DropDownActions options={editOption(kind, this.handleViewKind(kind))} i18nPrefix="KindsPage.actions" />
            );
        }
        return (
            <DropDownActions
                options={actionOptions(kind, this.handleViewKind(kind), this.handleDeleteKind(kind))}
                i18nPrefix="KindsPage.actions"
            />
        );
    };

    renderExplain() {
        return (
            <div style={{ marginTop: 9 }} onClick={() => this.setState({ showExplanation: true })}>
                <EuiIcon type={"questionInCircle"} size={"xl"} />
            </div>
        );
    }

    render() {
        const shopId = this.context.shopId.substr(0, 8);
        const settings = initialKindsTableSettings(
            "table.kinds-page.cannabis-soorten",
            initialKindsFilterAndSort(shopId, "pending"),
            ["created_at", "description_nl", "description_en"],
            { showSettings: false, pageSize: 10, refresh: false }
        );

        return (
            <EuiPage>
                <EuiPageSection>
                    <Explain
                        close={() => this.setState({ showExplanation: false })}
                        isVisible={this.state.showExplanation}
                        title="KindsPage Help"
                    >
                        <h1>Cannabis producten</h1>
                        <p>Gebruik deze pagina om snel product info aan te passen.</p>
                        <h2>Uitleg</h2>
                        <p>
                            De tabellen slaan hun instellingen op in je browser. Wil je dat resetten, gebruik dan het
                            potlood icoontje en druk op "Reset".
                        </p>
                    </Explain>
                    <ConfirmationDialog
                        isOpen={this.state.confirmationDialogOpen}
                        cancel={this.cancelConfirmation}
                        confirm={this.state.confirmationDialogAction}
                        question={this.state.confirmationDialogQuestion}
                    />
                    <div className="actions">{this.renderExplain()}</div>
                    <KindsTable key={"kinds"} initialTableSettings={settings} renderActions={this.renderActions} />
                </EuiPageSection>
            </EuiPage>
        );
    }
}

KindsPage.contextType = ShopContext;

export default KindsPage;
