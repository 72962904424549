import { EuiFlexItem, EuiIcon } from "@elastic/eui";
import React, { FC } from "react";
//@ts-expect-error - Could not find a declaration file for module 'react-currency-formatter'.
import Currency from "react-currency-formatter";

import { priceGridStyles } from "./PriceGridStyles";

interface PriceProps {
    prices: Record<string, number | null>;
    record?: { internal_product_id: string };
    setEditRecord?: (record: any) => void;
    getModalPrices?: (key: string, value: number, productId: string) => void;
    mode?: string;
}

interface PriceCardProps {
    label: string;
    value: number | null;
    onClick?: () => void;
    readOnly: boolean;
}

const PriceCard: FC<PriceCardProps> = ({ label, value, onClick, readOnly }) => {
    if (!value) return null;
    return (
        <EuiFlexItem
            grow={false}
            className="priceCard"
            onClick={onClick}
            style={{ pointerEvents: readOnly ? "none" : "auto" }}
        >
            <div className="priceCardHeader">
                <strong className="priceLabel">{label}</strong>
                {!readOnly && <EuiIcon color="primary" className="priceTableEditIcon" size="s" type="pencil"></EuiIcon>}
            </div>
            <div>
                <Currency quantity={value} currency="EUR" locale="nl_NL" />
            </div>
        </EuiFlexItem>
    );
};

export const PriceGrid: FC<PriceProps> = ({ prices, record, setEditRecord, getModalPrices, mode }) => {
    const handleClick = (key: string, value: number) => {
        if (mode === "cannabis" && setEditRecord && getModalPrices && record) {
            setEditRecord(record);
            getModalPrices(key, value, record.internal_product_id);
        }
    };

    //Sort the price cards by largest amount
    const sortedPrices = Object.fromEntries(
        Object.entries(prices)
            .filter(([key, value]) => value !== null)
            .sort(([keyA], [keyB]) => {
                return parseFloat(keyA) - parseFloat(keyB);
            })
    );

    return (
        <EuiFlexItem css={priceGridStyles}>
            <div className="priceGrid">
                {Object.keys(sortedPrices).map((key) => (
                    <PriceCard
                        key={key}
                        label={key}
                        value={prices[key]}
                        onClick={prices[key] ? () => handleClick(key, prices[key] as number) : undefined}
                        readOnly={!record}
                    />
                ))}
            </div>
        </EuiFlexItem>
    );
};
